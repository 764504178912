<template>
  <div class="blank-page">
    <BasePage :bannerUrl="banner" subTilte="南通市海衡招标有限公司欢迎您" content="规范服务 / 阳光清廉 / 示范引领 / 降本增效" :hideBanner="true"
      :show="true" :imageUrl="imageUrl" :title="title" :contentX="contentX">
      <el-row>
        <el-col :span="16" :offset="4">
          <div class="letter-guarantee-content">
            <div class="letter-guarantee-title">产 品 介 绍</div>
            <div class="letter-guarantee-line">
              <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
              <div class="letter-guarantee-line-box" style="background-color: #689CCE;height: 4px;"></div>
              <div class="letter-guarantee-line-box" style="background-color: #43474E;height: 4px;"></div>
              <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
            </div>
          </div>
          <div class="letter-guarantee-one">
            <div class="letter-guarantee-one-box">
              <div class="letter-guarantee-one-box-title">电子投标保函</div>
              <div class="letter-guarantee-one-box-line"></div>
              <div class="letter-guarantee-one-box-content">
                指担保公司等金融机构向招标人提供的保证投标人服约投标义务的担保文件，一旦投标人未耀行投标义务导致招标人产生损失时，出具担保的担保公司等金融机构向招标人承担代偿责任。</div>
            </div>
            <div class="letter-guarantee-one-img">
              <img src="@/assets/5e0ed02ea85eb.jpg" alt="" style="width: 100%;height: auto;">
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="width: 100%;background: #F5F5F5;padding: 10px 0 30px 0;">
        <el-row>
          <el-col :span="16" :offset="4">
            <div class="letter-guarantee-content">
              <div class="letter-guarantee-title">产 品 特 点</div>
              <div class="letter-guarantee-line">
                <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
                <div class="letter-guarantee-line-box" style="background-color: #689CCE;height: 4px;"></div>
                <div class="letter-guarantee-line-box" style="background-color: #43474E;height: 4px;"></div>
                <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
              </div>
            </div>
            <div class="letter-guarantee-two">
              <div class="letter-guarantee-two-top">
                <div class="two-box">
                  <img src="@/assets/ww1.png" alt="">
                  <div>与现金保证金同等法律效益</div>
                </div>
                <div class="two-box">
                  <img src="@/assets/ww2.png" alt="">
                  <div>手续简单</div>
                </div>
                <div class="two-box">
                  <img src="@/assets/ww3.png" alt="">
                  <div>全流程线上秒开</div>
                </div>
              </div>
              <div class="letter-guarantee-two-bottom">
                <div class="two-box">
                  <img src="@/assets/ww4.png" alt="">
                  <div>成本低廉</div>
                </div>
                <div class="two-box">
                  <img src="@/assets/ww5.png" alt="">
                  <div>付费可得</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="16" :offset="4">
          <div class="letter-guarantee-content">
            <div class="letter-guarantee-title">适 用 场 景</div>
            <div class="letter-guarantee-line">
              <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
              <div class="letter-guarantee-line-box" style="background-color: #689CCE;height: 4px;"></div>
              <div class="letter-guarantee-line-box" style="background-color: #43474E;height: 4px;"></div>
              <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
            </div>
          </div>
          <div class="letter-guarantee-one">
            <div class="letter-guarantee-one-img">
              <img src="@/assets/ISS_10290_10014_1.jpg" alt="" style="width: 100%;height: auto;">
            </div>
            <div class="letter-guarantee-one-box">
              <div class="letter-guarantee-one-box-content">
                投标人参与交易项目投标时，在递交保证金环节即可根据对应招标文件要求选择使用当前产品完成交纳，不需要再提供现金保证金。</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="width: 100%;background: #F5F5F5;padding: 10px 0 30px 0;">
        <el-row>
          <el-col :span="16" :offset="4">
            <div class="letter-guarantee-content">
              <div class="letter-guarantee-title">获 取 途 径</div>
              <div class="letter-guarantee-line">
                <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
                <div class="letter-guarantee-line-box" style="background-color: #689CCE;height: 4px;"></div>
                <div class="letter-guarantee-line-box" style="background-color: #43474E;height: 4px;"></div>
                <div class="letter-guarantee-line-box" style="margin-top: 2px;"></div>
              </div>
              <div style="font-size: 14px;text-align: center;font-weight: 600;margin-top: 14px;">
                通过海衡集采平台的交易系统进入保证金递交环节，即可选择“电子保函”并进行直接的线上申请操作</div>
            </div>
            <div class="letter-guarantee-four">
              <div class="letter-guarantee-four-box">
                <div
                  style="background: rgba(51, 153, 255, 1);border-radius: 50px;width: 32px; height: 32px;align-content: center;text-align: center;">
                  <img src="@/assets/c1.png" alt="">
                </div>
                <div style="margin-left: 10px;">
                  <div class="letter-guarantee-four-box-title">第一步</div>
                  <div>递交保证金环节选择“电子保函”递交方式</div>
                </div>
              </div>
              <div class="el-icon-right">
              </div>
              <div class="letter-guarantee-four-box">
                <div
                  style="background: rgba(51, 153, 255, 1);border-radius: 50px;width: 32px; height: 32px;align-content: center;text-align: center;">
                  <img src="@/assets/c2.png" alt="">
                </div>
                <div style="margin-left: 10px;">
                  <div class="letter-guarantee-four-box-title">第二步</div>
                  <div>选择出具保函的金融机构</div>
                </div>
              </div>
              <div class="el-icon-right">
              </div>
              <div class="letter-guarantee-four-box">
                <div
                  style="background: rgba(51, 153, 255, 1);border-radius: 50px;width: 32px; height: 32px;align-content: center;text-align: center;">
                  <img src="@/assets/c3.png" alt="">
                </div>
                <div style="margin-left: 10px;">
                  <div class="letter-guarantee-four-box-title">第三步</div>
                  <div>支付保函开具服务费</div>
                </div>
              </div>
              <div class="el-icon-right">
              </div>
              <div class="letter-guarantee-four-box">
                <div
                  style="background: rgba(51, 153, 255, 1);border-radius: 50px;width: 32px; height: 32px;align-content: center;text-align: center;">
                  <img src="@/assets/c4.png" alt="">
                </div>
                <div style="margin-left: 10px;">
                  <div class="letter-guarantee-four-box-title">第四步</div>
                  <div>在线获取保函文件</div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="letter-guarantee-about">
        <div class="letter-guarantee-about-bg">
          <el-row>
            <el-col :span="16" :offset="4">
              <div style="color: #FFF;padding: 24px 0;">
                <div style="font-size: 24px;font-weight: 500;">
                  联系我们 <span> CONTACT US</span>
                </div>
                <div class="letter-guarantee-about-bg-text"><span class="el-icon-location-outline"></span>
                  地址:江苏省南通市海门区长江南路777号海门区政务服务中心四层
                </div>
                <div class="letter-guarantee-about-bg-text"><span class="el-icon-message"></span> 邮编:226100</div>
                <div class="letter-guarantee-about-bg-text"><span class="el-icon-phone-outline"></span>
                  市场拓展部:0513-68069808</div>
                <div class="letter-guarantee-about-bg-text"><span class="el-icon-phone-outline"></span>
                  CA办理咨询:18906291896
                </div>
                <div class="letter-guarantee-about-bg-text"><span class="el-icon-phone-outline"></span>
                  派驻纪检监察组电话:0513-69909722</div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div> -->
    </BasePage>
    <!-- <div class="letter-guarantee-head">
      <el-row>
        <el-col :span="16" :offset="4">
          <div class="letter-guarantee-head-box">
            <div class="letter-guarantee-head-box-title">
              <div style="color: #FFF;font-size: 28px;font-weight: 600;">电 子 投 标 保 函</div>
              <div style="width: 140px;height: 2px;background: #98D1FF;margin: 24px 0;"></div>
              <div>
                <el-button style="background: #004ED9;color: #ffffff;border: none;">立 即 申 请</el-button>
              </div>
            </div>
            <div class="letter-guarantee-head-box-title">
              <img src="@/assets/w2.png" alt="" style="width: 100%;height: auto;">
            </div>
          </div>
        </el-col>
      </el-row>
    </div> -->

  </div>
</template>

<script>
import imgData from "@/data";
import { reactive, ref } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
export default {
  components: { BasePage },
  setup() {
    const data = reactive({
      ...imgData.FinancialImg,
    });
    const imageUrl = ref(require('@/assets/5bf4d5e995e24.png'));
    const title = ref('电子保函');
    const contentX = ref(`<button style='background: #004ED9;color: #ffffff;border: none;padding:12px;' @click="toYemian">立 即 申 请</button>`);
    return {
      ...data,
      imageUrl,
      title,
      contentX
    };
  },
  props: {},
  data() {
    return {
    }
  },
  mounted() { },
  methods: {
    toYemian() {
      window.open('https://fw.hhzb.com.cn/', '_blank');
    }
  },
  computed: {},
  watch: {},
}
</script>

<style scoped>
.blank-page {
  height: 100vh;
}
.letter-guarantee-head {
  width: 100%;
  background: #367bf0;
}
.letter-guarantee-head-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.letter-guarantee-head-box-title {
  width: 40%;
}
.letter-guarantee-content {
  width: 100%;
}
.letter-guarantee-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 48px;
}
.letter-guarantee-line {
  display: flex;
  width: 50%;
  margin: 0 auto;
  margin-top: 12px;
}
.letter-guarantee-line-box {
  width: 25%;
  height: 2px;
  background-color: #e3e3e3;
}
.letter-guarantee-one {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  align-items: center;
}
.letter-guarantee-one-box {
  width: 40%;
  text-align: left;
}
.letter-guarantee-one-box-title {
  font-size: 18px;
  font-weight: 600;
}
.letter-guarantee-one-box-line {
  width: 80px;
  height: 2px;
  background-color: #689cce;
  margin-top: 24px;
}
.letter-guarantee-one-box-content {
  margin-top: 24px;
  font-size: 16px;
}
.letter-guarantee-one-img {
  width: 40%;
  text-align: right;
}
.letter-guarantee-two {
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #2c293e;
}
.letter-guarantee-two-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}
.letter-guarantee-two-bottom {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
}
.two-box {
  display: flex;
  align-items: center;
}
.two-box div {
  margin-left: 16px;
}
.letter-guarantee-four {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
  align-items: center;
}
.letter-guarantee-four-box {
  display: flex;
  font-size: 16px;
  color: #494748;
  align-items: center;
  font-weight: 600;
}
.letter-guarantee-four-box-title {
  font-size: 14px;
  color: #bfc0c3;
  margin-bottom: 10px;
}
.letter-guarantee-about-bg {
  width: 100%;
  background: #687588;
}
.letter-guarantee-about-bg-text {
  font-size: 16px;
  margin-top: 8px;
}
</style>
